import React from "react"
import { Link, graphql } from "gatsby"

import Bio from "../components/bio"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { rhythm } from "../utils/typography"

import { RichText } from "prismic-reactjs"
import { linkResolver } from "../utils/linkResolver"

const BlogIndex = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata.title
  const blog_posts = data.prismic.allBlog_posts.edges

  return (
    <Layout location={location} title={siteTitle}>
      <SEO title="All posts" />
      <Bio />
      {blog_posts.map(({ node }) => {
        const title = RichText.asText(node.post_title) || node.uid
        return (
          <article key={node.uid}>
            <header>
              <h3
                style={{
                  marginBottom: rhythm(1 / 4),
                }}
              >
                <Link
                  style={{ boxShadow: `none` }}
                  to={linkResolver(node._meta)}
                >
                  {title}
                </Link>
              </h3>
              <small>{node.date_published}</small>
            </header>
            <section>
              <p
                dangerouslySetInnerHTML={{
                  __html:
                    RichText.asText(node.post_content)
                      .substring(0, 180)
                      .trim() + "…" || "",
                }}
              />
            </section>
          </article>
        )
      })}
    </Layout>
  )
}

export default BlogIndex

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    prismic {
      allBlog_posts(sortBy: date_published_DESC) {
        edges {
          node {
            post_title
            date_published
            post_image
            post_content
            _meta {
              id
              uid
              type
            }
          }
        }
      }
    }
  }
`
